import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {
  API_GET_BANK_ACCOUNT,
  API_GET_CREATE_PAYOUT,
  token,
  Currencies,
  API_GET_BALANCE
} from 'constants/constants';
import { NotificationContainer } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { thousands_separators } from '../../../../../../constants/usefulFunctions';

const UserInfo = (user) => {
  const user_id = user.user_id;
  const basicInfo = user.basicInfo;
  const [state, setState] = useState();
  const [currency, setCurrency] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      await axios.post(API_GET_BANK_ACCOUNT, { user_id }, token).then((res) => {
        let data = res.data.data;
        setState(data);
      });
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      await axios.post(API_GET_BALANCE, { user_id }, token).then((res) => {
        let data = res.data.data;

        setCurrency(data);
      });
    }

    fetchData();
  }, []);

  const [values, setValues] = useState({
    type: 'Wire',
    amount: 0,
    currency: 0,
    description: '',
    account: 0,
    success: false,
    error: false,
    created_at: moment().format('YYYY-MM-DD HH:mm:ss')
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [alert, setAlert] = useState({ open: false });
  const handleRequestClose = () => {
    setAlert({ open: false });
  };

  function handleSaveUser() {
    setLoading(true);
    handleRequestClose();
    axios
      .post(
        API_GET_CREATE_PAYOUT,
        {
          type: values.type,
          amount: values.amount,
          currency_id: values.currency,
          description: values.description,
          account_id: values.account,
          created_at: values.created_at,
          user_id: basicInfo.id
        },
        token
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
        setLoading(false);
      })
      .catch((error) => {
        let failedValue = '';
        if (typeof error.response.data.data === 'string') {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ': ' + error.response.data.data[prop] + ' - ';
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setLoading(false);
      });
  }

  let ibanBank = state && state.find((item) => item.id === values.account);

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className="row">
      <>
        <NotificationContainer />
        <SweetAlert
          show={values.success}
          success
          title={'Success'}
          confirmBtnText={'Close'}
          onConfirm={onConfirm}>
          Successfully Changed!
        </SweetAlert>
        <SweetAlert
          show={values.error}
          error
          title={'Error'}
          confirmBtnText={'Close'}
          onConfirm={onConfirm}>
          Error! {values.code ? values.code : ''}
        </SweetAlert>
      </>

      <div className="col-xl-6 col-lg-12 col-md-12 col-12">
        <div className={classes.root}>
          <TextField
            label="Amount"
            value={values.amount}
            onChange={handleChange('amount')}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className={classes.root}>
          <TextField
            select
            label="Currency"
            value={values.currency}
            onChange={handleChange('currency')}
            InputLabelProps={{
              shrink: true
            }}>
            {currency &&
              currency.map((option) => (
                <MenuItem key={option.currency_id} value={option.currency_id}>
                  {Currencies[option.currency_id]}
                </MenuItem>
              ))}
          </TextField>
        </div>
        <div className={classes.root}>
          <TextField
            multiline
            label={'Description'}
            value={values.description}
            onChange={handleChange('description')}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className={classes.root}>
          <TextField
            select
            label="Account"
            value={values.account}
            onChange={handleChange('account')}
            InputLabelProps={{
              shrink: true
            }}>
            {state &&
              state.map(
                (option) =>
                  option.status === 'Approved' && (
                    <MenuItem key={option.id} value={option.id}>
                      {option.iban}
                    </MenuItem>
                  )
              )}
          </TextField>
        </div>
        <div className={classes.root}>
          <TextField
            select
            label="Type"
            value={values.type}
            onChange={handleChange('type')}
            InputLabelProps={{
              shrink: true
            }}>
            <MenuItem value="Wire" key={1}>
              Wire
            </MenuItem>
            <MenuItem value="Parsian" key={2}>
              Parsian
            </MenuItem>
            <MenuItem value="Sepah" key={3}>
              Sepah
            </MenuItem>
          </TextField>
        </div>
        <div className={classes.root}>
          <TextField
            label="Created at"
            value={values.created_at}
            onChange={handleChange('created_at')}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="d-flex flex-row align-items-center mt-2">
          {/*{ <Button disabled={loading} style={{width: "100px"}} color="primary" className=" jr-btn jr-btn-lg bg-primary text-white MuiButton-contained" onClick={handleSaveUser}>{loading ? <CircularProgress color={"#fff"} size={14} />: "Save"}</Button>}*/}
          <div>
            <Button
              style={{ width: '100px' }}
              variant="contained"
              className="jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
              onClick={() => setAlert({ open: true })}>
              {loading ? <CircularProgress color="inherit" size={14} /> : 'Save'}
            </Button>
            <div style={{ minWidth: '500px' }}>
              <Dialog open={alert.open} onClose={handleRequestClose}>
                <DialogTitle>{'Verify Payout'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <div className="text-center">Amount:</div>
                    <h2
                      style={{ color: '#792a87' }}
                      className="text-center">{`${thousands_separators(
                      Currencies[values.currency] === 'IRR'
                        ? Math.trunc(values.amount)
                        : values.amount
                    )} ${Currencies[values.currency]} `}</h2>
                    <div>
                      Description: <div className="text-black">{values.description}</div>
                    </div>
                    <div className="mt-2">
                      Account: <div className="text-black">{ibanBank && ibanBank.iban}</div>
                    </div>
                    <div className="mt-2">
                      Type: <div className="text-black">{values.type}</div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRequestClose} color="secondary">
                    Cancel
                  </Button>

                  <Button disabled={loading} color="primary" onClick={handleSaveUser}>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
