import React from "react";
import DatePicker from "react-datepicker2";
import Switch from "@mui/material/Switch";
import AppButton from "components/AppButton";

function YPDatePicker({
  date,
  onHandleDate,
  onChangeTo,
  onChangeFrom,
  onChangeSwitch,
  loading,
  showSwitch,
}) {
  return (
    <div>
      <span className="mr-3 ml-0 float-left">Date:</span>
      <DatePicker
        onChange={onChangeFrom}
        isGregorian={date.isGregorian}
        value={date.from}
        timePicker={false}
        className="float-left text-center "
      />

      <DatePicker
        onChange={onChangeTo}
        isGregorian={date.isGregorian}
        value={date.to}
        timePicker={false}
        className="float-left ml-3 text-center"
      />

      <AppButton
        size="small"
        onClick={onHandleDate}
        text="Change"
        style={{ marginLeft: "10px" }}
        loading={loading}
      />
      <br />
      {showSwitch && (
        <div className="mt-3 ">
          <span className="mr-3 float-left">Gregorian:</span>

          <Switch
            checked={date.isGregorian}
            onChange={onChangeSwitch}
            color="primary"
            size="small"
          />
        </div>
      )}
    </div>
  );
}

export default YPDatePicker;
