import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { Currencies, ApiURL, boot, Main_Stage, forth_Status } from 'constants/constants';
import { createDateLookup, getIdentityStatus2 } from 'constants/usefulFunctions';
import useAPI from 'services/hook';
import APIs from 'services/API/ProfileUser';
import MainTable from 'components/MainTable';

const Gateways = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const apiGetGateways = useAPI(APIs.apiGetGateways);
  const fetchData = async () => {
    const result = await apiGetGateways.request({ user_id });
    if (result) {
      var formatData = result?.map((item) => {
        item.websiteLogo = ApiURL + item.website_logo;
        item.agent = item.agent_first_name + ' ' + item.agent_last_name;
        item.agentContact = item.agent_email + ' ' + item.agent_mobile;
        return item;
      });
      setData(formatData);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'WebSite',
      field: 'website',
      editable: 'never',
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <Avatar
                className="size-50 border"
                alt={rowData.website_name}
                src={rowData.websiteLogo}
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <p>
                <a
                  target="_blanck"
                  href={
                    rowData.website_domain.slice(0, 3) === 'htt'
                      ? rowData.website_domain
                      : 'http://' + rowData.website_domain
                  }
                  className="mb-0 jr-fs-lg">
                  {rowData.website_domain.slice(0, 3) === 'htt'
                    ? rowData.website_domain
                    : 'https://' + rowData.website_domain}
                </a>
              </p>
              <a href={`/app/gateway/${rowData.id}`} className="mb-0 jr-fs-lg">
                {rowData.website_name}
              </a>
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Agent',
      field: 'agent',
      editable: 'never',
      render: (rowData) => (
        <span>
          {rowData.agent}
          <br />
          {rowData.agentContact}
        </span>
      )
    },
    { title: 'Type', field: 'type', editable: 'never' },
    {
      title: 'Country',
      field: 'country_id',
      editable: 'never',
      render: (rowData) =>
        rowData.country_id && boot.countries
          ? boot.countries.find((o) => o.id === rowData.country_id).name_en
          : '-'
    },
    {
      title: 'Currency',
      field: 'currency_id',
      editable: 'never',
      render: (rowData) => Currencies[rowData.currency_id]
    },
    createDateLookup(),

    { title: 'Stage', field: 'last_status.stage', lookup: Main_Stage },
    {
      title: 'Status',
      field: 'last_status.status',
      lookup: forth_Status,
      render: (rowData) => getIdentityStatus2(rowData.last_status.status)
    },
    { title: 'Description', field: 'last_status.admin_description' }
  ];
  const options = {
    actionsColumnIndex: columns.length
  };

  const apiChangeGateway = useAPI(APIs.apiChangeGateway, true);
  const updateRow = async (user_id, id, status, stage, description, updateData) => {
    const result = await apiChangeGateway.request({
      user_id,
      id,
      status,
      description,
      stage
    });
    if (result) setData([...updateData]);
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        loading={loading}
        columns={columns}
        data={data}
        title="Gateways"
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updateData = [...data];
              updateData[data?.indexOf(oldData)] = newData;
              updateRow(
                oldData.user_id,
                oldData.id,
                newData.last_status.status,
                newData.last_status.stage,
                newData.last_status.admin_description,
                updateData
              );
            }, 600);
            resolve();
          })
        }
      />
    </div>
  );
};

export default Gateways;
