import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { API_GET_LOGIN_API_ENDPOINT } from "constants/constants";

import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  showAuthSuccessMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from "redux/actions/Auth";
import { API_GET_BOOT } from "constants/constants";
import { history } from "redux/store";
import {
  SET_WAITING_APPROVED_COUNT,
  SET_WAITING_APPROVED_COUNT_SUCCESS,
} from "redux/constants";

const signOutRequest = async () =>
  await signOut()
    .then((authToken) => authToken)
    .catch((error) => error);

const signInUserWithEmailPasswordRequest = (request) => {
  const LOGIN_API_ENDPOINT = API_GET_LOGIN_API_ENDPOINT;
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  };

  return fetch(LOGIN_API_ENDPOINT, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};

function* signInUserWithEmailPassword({ payload }) {
  try {
    const response = yield call(signInUserWithEmailPasswordRequest, payload);

    if (response.status === "success") {
      function axiosTest() {
        return axios
          .post(API_GET_BOOT, {}, response.data.token)
          .then((response) => {
            return response.data.data;
          });
      }

      axiosTest().then((data) => {
        localStorage.setItem("boot", JSON.stringify(data ?? []));
      });
      let {
        token,
        first_name,
        last_name,
        waiting_approved_count,
        permissions,
      } = response.data;
      yield put({
        type: SIGNIN_USER_SUCCESS,
        payload: {
          authToken: token,
          fullName: `${first_name} ${last_name}`,
          waiting_approved_count,
          permissions,
        },
      });
      yield put(
        showAuthSuccessMessage("Login Successfull, Redirect to Panel...")
      );

      history.push("/app/dashboard");
    } else {
      yield put(showAuthMessage("Token Incorrect!"));
    }
  } catch (error) {
    console.log(
      "🚀 ~ file: Auth.js ~ line 70 ~ function*signInUserWithEmailPassword ~ error",
      error
    );
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  yield put({ type: SIGNOUT_USER_SUCCESS });
}

function* setWaitingApprovedCountRequest({ data }) {
  yield put({ type: SET_WAITING_APPROVED_COUNT_SUCCESS, payload: data.count });
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* setWaitingApproved() {
  yield takeEvery(SET_WAITING_APPROVED_COUNT, setWaitingApprovedCountRequest);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser), fork(setWaitingApproved)]);
}
