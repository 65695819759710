import React from "react";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";

const AdminInfo = () => {
  const { fullName } = useSelector((state) => state.auth);
  
  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt="..."
        src={"https://via.placeholder.com/150x150"}
        className="user-avatar "
      />
      <div className="user-detail">
        <h4 className="user-name">
          {fullName}
          <i className="zmdi zmdi-hc-fw align-middle" />
        </h4>
        {/* <p className="mb-0 jr-fs-11 text-grey">
          {localStorage.getItem("department")}
        </p> */}
      </div>
    </div>
  );
};

export default AdminInfo;
