import React from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const TextMessages = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Route
        path={`${match.url}/new`}
        component={asyncComponent(() => import("./SendNewMessage"))}
      />
    </div>
  );
};

export default TextMessages;
