import React from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";
import Echo from "laravel-echo";
import { useDispatch, useSelector } from "react-redux";
import { setWaitingApprovedCount } from "redux/actions/Auth";
window.pusher = require("pusher-js");
// --------------------------------------------------------------------------------
const Transactions = () => {
  const dispatch = useDispatch();
  const { waiting_approved_count } = useSelector((state) => state.auth);

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "myKey",
    wsHost: "api.finotransfer.com",
    wsPort: 8443,
    wssPort: 8443,
    forceTLS: true,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
  });

  window.Echo.channel("waiting_approved_count").listen(
    "WaitingApprovedCount",
    (event) => {
      console.log({ event });
      dispatch(setWaitingApprovedCount(event.count));
    }
  );

  return (
    <>
      <NavHeader title="sidebar.transactions" permission="Transaction" />
      <NavMenu
        title="sidebar.waiting.for.approve"
        icon="zmdi zmdi-spinner"
        url="/app/transactions/pending"
        badge={waiting_approved_count}
        permission="Pending Transaction"
      />
      <NavMenu
        title="sidebar.approve.transactions"
        icon="zmdi zmdi-badge-check"
        url="/app/transactions/approved"
        permission="Approved Transaction"
      />
      <NavMenu
        title="sidebar.declined.transactions"
        icon="zmdi zmdi-block"
        url="/app/transactions/declined"
        permission="Declined Transaction"
      />
      <NavMenu
        title="sidebar.refunded.transactions"
        icon="zmdi zmdi-undo"
        url="/app/transactions/refunded"
        permission="Refunded Transaction"
      />
      <NavMenu
        title="sidebar.executive.transactions"
        icon="zmdi zmdi-caret-right-circle"
        url="/app/transactions/executive"
        permission="Executive Transaction"
      />
      <NavMenu
        title="sidebar.transactions.allTransactions"
        icon="zmdi zmdi-format-line-spacing"
        url="/app/transactions/all"
        permission="All Transaction"
      />
      <NavMenu
        title="sidebar.exchanges"
        icon="zmdi zmdi zmdi-format-list-bulleted"
        url="/app/transactions/exchanges-list"
        permission="Exchange Transaction"
      />
      <NavMenu
        title="sidebar.transfers"
        icon="zmdi zmdi zmdi-format-list-bulleted"
        url="/app/transactions/transfers-list"
        permission="Transfer Transaction"
      />
    </>
  );
};

export default Transactions;
