import React, { useEffect, useState } from 'react';
import { Currencies, ApiURL, boot } from 'constants/constants';
import { getIdentityStatus, createDateLookup, deleteDateLookup } from 'constants/usefulFunctions';
import Avatar from '@mui/material/Avatar';
import MainTable from 'components/MainTable';
import useAPI from 'services/hook';
import APIs from 'services/API/ProfileUser';

const BankAccount = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const apiGetBankAccount = useAPI(APIs.apiGetBankAccount);
  const fetchData = async () => {
    const result = await apiGetBankAccount.request({ user_id });
    if (result) {
      let new_array = result?.map((item) => {
        item.responseFullName = item.bank_response_first_name + ' ' + item.bank_response_last_name;
        item.bankResponse =
          item.bank_response_account_number +
          ' ' +
          item.bank_response_status +
          ' ' +
          item.bank_response_comment;
        return item;
      });

      setData(new_array);
      setLoading(false);
    }
  };

  const apiChangeStatusBankAccount = useAPI(APIs.apiChangeStatusBankAccount, true);
  const updateRow = async (id, status, description, updatedData) => {
    const result = await apiChangeStatusBankAccount.request({
      user_id,
      id,
      status,
      description
    });
    if (result) setData([...updatedData]);
  };

  const apiDeleteBankAccount = useAPI(APIs.apiDeleteBankAccount, true);
  const deleteRow = async (bank_account_id, user_id, updatedData) => {
    const result = await apiDeleteBankAccount.request({
      user_id,
      bank_account_id
    });
    if (result) setData([...updatedData]);
  };

  const apiRestoreBankAccount = useAPI(APIs.apiRestoreBankAccount, true);
  const RestoreDeleteRow = async (bank_account_id, user_id) => {
    const result = await apiRestoreBankAccount.request({
      user_id,
      bank_account_id
    });
    if (result) fetchData();
  };

  const apiCreatBankAccount = useAPI(APIs.apiCreatBankAccount, true);
  const addRow = async (
    currency_id,
    account_holder_name,
    iban,
    bank_branch,
    swift_code,
    updatedData
  ) => {
    const result = await apiCreatBankAccount.request({
      user_id,
      currency_id,
      account_holder_name,
      bank_branch,
      iban,
      swift_code
    });
    if (result) setData([...updatedData]);
  };

  const columns = [
    {
      title: 'Currency',
      field: 'currency_id',
      lookup: Currencies,
      render: (rowData) => Currencies[rowData.currency_id]
    },
    { title: 'Name', field: 'account_holder_name' },
    {
      title: 'Response FullName',
      field: 'responseFullName',
      editable: 'never'
    },
    {
      title: 'Response Account Number',
      field: 'bank_response_account_number',
      editable: 'never'
    },
    {
      title: 'Response Status',
      field: 'bank_response_status',
      editable: 'never'
    },
    {
      title: 'Response Comment',
      field: 'bank_response_comment',
      editable: 'never'
    },
    { title: 'Branch', field: 'bank_branch' },
    {
      title: 'Attachment',
      field: 'file',
      editable: 'never',
      render: (rowData) =>
        rowData && rowData.file ? (
          <>
            <div className="jr-profile-banner-top-left">
              <div className="jr-profile-banner-avatar">
                <a target="_blank" href={ApiURL + rowData.file}>
                  <Avatar
                    className="size-50 border"
                    alt="Bank Account File"
                    src={ApiURL + rowData.file}
                  />
                </a>
              </div>
            </div>
          </>
        ) : (
          '-'
        )
    },
    { title: 'IBAN', field: 'iban' },
    { title: 'Swift', field: 'swift_code' },
    createDateLookup(),
    deleteDateLookup({ restoreFunc: RestoreDeleteRow }),
    { title: 'Description', field: 'description' },
    {
      title: 'Admin',
      field: 'admin_id',
      editable: 'never',
      initialEditValue: Number(localStorage.getItem('admin_id')),
      render: (rowData) =>
        boot.admins && rowData.admin_id
          ? boot.admins.find((o) => o.id === rowData.admin_id).first_name +
            ' ' +
            boot.admins.find((o) => o.id === rowData.admin_id).last_name
          : boot.admins.find((o) => o.id === Number(localStorage.getItem('admin_id'))).first_name +
            ' ' +
            boot.admins.find((o) => o.id === Number(localStorage.getItem('admin_id'))).last_name
    },
    {
      title: 'Status',
      field: 'status',
      initialEditValue: 'Pending',
      lookup: {
        Approved: 'Approved',
        Declined: 'Declined',
        Pending: 'Pending',
        Suspend: 'Suspend'
      },
      render: (rowData) => rowData && getIdentityStatus(rowData.status)
    }
  ];
  const options = {
    actionsColumnIndex: columns.length
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        isLocalization
        loading={loading}
        columns={columns}
        data={data}
        title="Bank Accounts"
        onRowAdd={(newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = [...data, newData];
              addRow(
                newData.currency_id,
                newData.account_holder_name,
                newData.iban,
                newData.bank_branch,
                newData.swift_code,
                updatedData
              );
              resolve();
            }, 600);
          })
        }
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = [...data];
              updatedData[data?.indexOf(oldData)] = newData;
              updateRow(oldData.id, newData.status, newData.description, updatedData);
              resolve();
            }, 500);
          })
        }
        onRowDelete={(oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = data?.filter((item) => item.id !== oldData.id);
              deleteRow(oldData.id, oldData.user_id, updatedData);
              resolve();
            }, 600);
          })
        }
      />
    </div>
  );
};

export default BankAccount;
