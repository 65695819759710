import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const INFORMATION_DIALOG = "INFORMATION_DIALOG";
export const CONFIRMATION_DIALOG = "CONFIRMATION_DIALOG";

const MessageDialog = ({
  status = "success",
  title,
  description,
  onConfirm,
  onCancel,
  confirmBtnText = "OK",
  cancelBtnText = "Cancel",
}) => {
  const renderIcon = (status) => {
    let icon = null;
    switch (status) {
      case "success":
        icon = <TaskAltIcon sx={{ color: "#FFFFFF", width: 40, height: 40 }} />;
        break;
      case "error":
        icon = (
          <ErrorOutlineIcon sx={{ color: "#FFFFFF", width: 40, height: 40 }} />
        );
        break;
      case "warning":
        icon = (
          <WarningAmberIcon sx={{ color: "#FFFFFF", width: 40, height: 40 }} />
        );
        break;
      default:
        break;
    }
    return icon;
  };

  const renderColor = (status) => {
    let color = "";
    switch (status) {
      case "success":
        color = "#2e7d32";
        break;
      case "error":
        color = "#d32f2f";
        break;
      case "warning":
        color = "#ed6c02";
        break;
      default:
        break;
    }
    return color;
  };

  return (
    <Dialog
      open
      sx={{
        ".MuiDialogContent-root": {
          padding: 0,
        },
      }}
      onClick={status === "warning" ? onCancel : onConfirm}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <Box
          sx={{
            padding: 1,
            backgroundColor: renderColor(status),
          }}
        >
          {renderIcon(status)}
        </Box>
        <Box sx={{ pt: 3, pb: 2 }}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box sx={{ px: 3, mb: 2, display: description ? "block" : "none" }}>
          <Typography variant="p">{description}</Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        {status === "warning" && (
          <Button color={status} variant="outlined" onClick={onCancel}>
            {cancelBtnText}
          </Button>
        )}
        <Button color={status} variant="contained" onClick={onConfirm}>
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageDialog;
