import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Income = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/payout-fee`}
        component={asyncComponent(() => import("./routes/PayoutFee"))}
      />
      <Route
        path={`${match.url}/exchange-fee`}
        component={asyncComponent(() => import("./routes/ExchangeFee"))}
      />
      <Route
        path={`${match.url}/transfer-fee`}
        component={asyncComponent(() => import("./routes/TransferFee"))}
      />
    </Switch>
  </div>
);

export default Income;
