import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { store, history, persistor } from "./redux/store";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import App from "./containers/App";

const MainApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <SnackbarProvider maxSnack={5}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </SnackbarProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default MainApp;
