import { capitalizeFirstLetter } from "constants/usefulFunctions";
import MaterialTable from "material-table";
import React, { memo, useCallback } from "react";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";

const MainTable = ({
  loading,
  columns,
  data = [],
  title = "",
  customOptions,
  isLocalization = true,
  onRowUpdate,
  onRowDelete,
  onRowAdd,
  actions,
  parentChildData,
}) => {
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
    addRowPosition: "first",
    exportCsv: (columns, data) => {
      exportToCSV(data, title);
    },

    ...customOptions,
  };

  const localization = {
    header: {
      actions: "",
    },
  };

  const columnsGenerator = useCallback(() => {
    if (data.length) {
      const columns = [];

      Object.keys(data[0])?.map((item) => {
        const splited = item.replaceAll("_", " ").split(" ");
        const capitilizedArr = splited?.map((word) =>
          capitalizeFirstLetter(word)
        );
        const title = capitilizedArr?.join(" ");

        if (item !== "tableData") columns.push({ title, field: item });
      });

      return columns;
    }
  }, [data]);

  return (
    <MaterialTable
      actions={actions}
      isLoading={loading}
      title={title}
      options={options}
      columns={columns ?? columnsGenerator()}
      data={data}
      localization={isLocalization && localization}
      editable={{ onRowUpdate, onRowDelete, onRowAdd }}
      parentChildData={parentChildData}
    />
  );
};

export default memo(MainTable);

const exportToCSV = (csvData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  saveAs(data, fileName + fileExtension);
};
