import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
} from "constants/ActionTypes";
import { SET_WAITING_APPROVED_COUNT_SUCCESS } from "redux/constants";
// --------------------------------------------------------------------------------
const initialState = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  showSuccessMessage: false,
  initURL: "",
  authToken: "",
  fullName: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        ...action.payload,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case SHOW_SUCCESS_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showSuccessMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        showSuccessMessage: false,
        loader: false,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authToken: "",
        fullName: "",
        waiting_approved_count: "",
      };
    }
    case SET_WAITING_APPROVED_COUNT_SUCCESS:
      return {
        ...state,
        waiting_approved_count: action.payload,
      };
    default:
      return state;
  }
};
