import React, { useEffect, useState } from 'react';
import { boot } from 'constants/constants';
import Button from '@mui/material/Button';
import { createDateLookup } from 'constants/usefulFunctions';
import MainTable from 'components/MainTable';
import Loading from 'components/Loading';
import useAPI from 'services/hook';
import APIs from 'services/API/ProfileUser';

function AdminComments({ user_id, basicInfo }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Object.keys(basicInfo).length !== 0) fetchData();
  }, [basicInfo]);

  const apiGetComments = useAPI(APIs.apiGetComments);
  const fetchData = async () => {
    const result = await apiGetComments.request({ user_id });
    if (result) {
      setData([...result]);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Admin',
      field: 'admin_id',
      editable: 'never',
      initialEditValue: Number(localStorage.getItem('admin_id')),
      render: (rowData) =>
        boot.admins && rowData.admin_id
          ? boot.admins.find((o) => o.id === rowData.admin_id).first_name +
            ' ' +
            boot.admins.find((o) => o.id === rowData.admin_id).last_name
          : boot.admins.find((o) => o.id === Number(localStorage.getItem('admin_id'))).first_name +
            ' ' +
            boot.admins.find((o) => o.id === Number(localStorage.getItem('admin_id'))).last_name
    },
    {
      title: 'Comment',
      field: 'comment',
      editComponent: (props) => (
        <textarea value={props.value} onChange={(e) => props.onChange(e.target.value)} rows={7} />
      )
    },
    {
      title: 'Attachment',
      field: 'attachment',
      render: (rowData) => {
        if (rowData.attachment) {
          return (
            <p className="text-center">
              <a
                className="pr-3"
                target="_blank"
                href={'https://admin.api.yekpay.com/' + rowData?.attachment}>
                <i
                  style={{ fontSize: '24px' }}
                  className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary"
                />
              </a>
            </p>
          );
        }
        return null;
      },
      editComponent: (props) => (
        <>
          <Button className="bg-light jr-btn jr-btn-sm text-dark mt-2" component="label">
            <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
            Choose File
            <input
              type="file"
              onChange={(e) => props.onChange(e.target.files[0])}
              style={{ display: 'none' }}
            />
          </Button>
          {props.rowData.attachment && (
            <div className="mt-3">
              File Name: <span className="text-success mr-2">{props.rowData.attachment.name}</span>
              File Size:{' '}
              <span className="text-success">
                {Math.trunc(props.rowData.attachment.size / 1024)}KB
              </span>
            </div>
          )}
        </>
      )
    },
    createDateLookup()
  ];

  const options = {
    actionsColumnIndex: columns.length,
    addRowPosition: 'first'
  };

  const apiDeleteComment = useAPI(APIs.apiDeleteComment, true);
  const deleteRow = async (id, updatedData) => {
    setLoading(true);
    const result = await apiDeleteComment.request({ user_id, id });
    if (result) {
      setLoading(false);
      setData([...updatedData]);
    }
  };

  const apiCreateComment = useAPI(APIs.apiCreateComment, true);
  const addRow = async (comment, attachment, updatedData) => {
    setLoading(true);
    const newData = new FormData();
    newData.append('comment', comment);
    newData.append('file', attachment);
    newData.append('user_id', user_id);

    const result = await apiCreateComment.request(newData);
    if (result) {
      setData([...updatedData]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  if (!data) return <Loading />;
  return (
    <div className="card">
      <MainTable
        isLocalization
        loading={loading}
        customOptions={options}
        columns={columns}
        data={data}
        title="Comments"
        onRowDelete={(oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = data?.filter((item) => item.id !== oldData.id);
              deleteRow(oldData.id, updatedData);
              resolve();
            }, 600);
          })
        }
        onRowAdd={(newData) =>
          new Promise((resolve) => {
            setTimeout(async () => {
              const updatedData = [...data];
              updatedData.push(newData);

              await addRow(newData.comment, newData?.attachment, updatedData);
              resolve();
            }, 500);
          })
        }
      />
    </div>
  );
}

export default React.memo(AdminComments);
