import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import SidenavContent from "./SidenavContent";
import AdminInfo from "app/routes/Settings/Profile/routes/AdminInfo";
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { toggleCollapsedNav, updateWindowWidth } from "redux/actions/Setting";

const SideNav = () => {
  const dispatch = useDispatch();

  const { navCollapsed, drawerType, width, navigationStyle } = useSelector(
    ({ settings }) => {
      return {
        navCollapsed: settings.navCollapsed,
        drawerType: settings.drawerType,
        width: settings.width,
        navigationStyle: settings.navigationStyle,
      };
    },
    shallowEqual
  );

  const onToggleCollapsedNav = () => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, []);

  let drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "d-xl-flex"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ""
    : "d-flex";

  let type = "permanent";
  if (
    drawerType.includes(COLLAPSED_DRAWER) ||
    (drawerType.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = "temporary";
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = "";
    type = "temporary";
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes("temporary") ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: "side-nav",
        }}
      >
        <AdminInfo />
        <SidenavContent />
      </Drawer>
    </div>
  );
};

export default SideNav;
