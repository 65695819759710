import { showMessageDialog } from "redux/actions/DialogAction";
import { useState } from "react";
import { store } from "redux/store";

const useAPI = (apiFunc, showSuccessModal = false) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState("");

  const fail = (data, problem) => {
    setError(true);
    setLoading(false);

    if (problem === "NETWORK_ERROR") {
      store.dispatch(
        showMessageDialog({
          title: "Network Error",
          description: "Network connection is not available",
          status: "error",
        })
      );
    }

    if (
      problem === "SERVER_ERROR" ||
      problem === "TIMEOUT_ERROR" ||
      problem === "CONNECTION_ERROR"
    ) {
      store.dispatch(
        showMessageDialog({
          title: "Server Error",
          description:
            "There is a problem with the server, please try again later",
          status: "error",
        })
      );
    }

    if (problem === "CONNECTION_ERROR") {
      store.dispatch(
        showMessageDialog({
          title: "Connection Error",
          description:
            "There is a problem with the server, please try again later",
          status: "error",
        })
      );
    }

    if (problem === "CLIENT_ERROR") {
      let description = "Error";
      if (data.data) description = Object.values(data.data);
      store.dispatch(
        showMessageDialog({
          title: "Error",
          description,
          status: "error",
        })
      );
    }
  };

  const success = (data) => {
    setLoading(false);
    setData(data);

    if (showSuccessModal)
      store.dispatch(
        showMessageDialog({
          title: "Successfully changed!",
          status: "success",
        })
      );

    if (data === null) return "ok";
    if (data === "") return "ok";
    return data;
  };

  const request = async (...args) => {
    setLoading(true);

    //calling api
    const result = await apiFunc(...args);

    //on Fails
    if (!result.ok) return fail(result.data, result.problem);

    //on Success
    return success(result.data?.data);
  };

  return { loading, error, data, request };
};
export default useAPI;
