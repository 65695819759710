import React, { useEffect, useState } from 'react';
import { ApiURL, boot } from 'constants/constants';
import {
  createDateLookup,
  getIdentityStatus,
  renderAdmin,
  updateDateLookup
} from '../../../../../constants/usefulFunctions';
import Avatar from '@mui/material/Avatar';
import DefaultAvatar from 'assets/images/default/company-logo.png';
import useAPI from 'services/hook';
import APIs from 'services/API/ProfileUser';
import MainTable from 'components/MainTable';

const Company = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const apiGetCompany = useAPI(APIs.apiGetCompany);
  const fetchData = async () => {
    const result = await apiGetCompany.request({ user_id });
    if (result) setData(result);
    setLoading(false);
  };

  const apiChangeStatusCompany = useAPI(APIs.apiChangeStatusCompany, true);
  const updateRow = async (id, status, description, updatedData) => {
    const result = await apiChangeStatusCompany.request({
      user_id,
      id,
      status,
      description
    });
    if (result) setData([...updatedData]);
  };

  const columns = [
    renderAdmin(),
    {
      title: 'Country',
      field: 'country_id',
      editable: 'never',
      render: (rowData) =>
        boot.countries && rowData.country_id
          ? boot.countries.find((o) => o.id === rowData.country_id).name_en
          : '-'
    },
    { title: 'Email', field: 'email', editable: 'never' },
    {
      title: 'Name',
      field: 'name',
      editable: 'never',
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <Avatar
                className="size-50 border"
                alt={rowData.name}
                src={rowData.logo != null ? ApiURL + rowData.logo : DefaultAvatar}
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <p className="mb-0">{rowData.name}</p>
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Files',
      field: 'last_changes',
      editable: 'never',
      render: (rowData) => (
        <>
          {rowData.last_changes && (
            <>
              <a href={ApiURL + rowData.last_changes}>Last Changes</a>
              <br />
            </>
          )}
          {rowData.official_newspaper && (
            <>
              <a href={ApiURL + rowData.official_newspaper}>Official Newspaper</a>
              <br />
            </>
          )}
          {rowData.owner_identity && (
            <>
              <a href={ApiURL + rowData.owner_identity}>Owner Identity</a>
              <br />
            </>
          )}
          {rowData.ownership_document && (
            <>
              <a href={ApiURL + rowData.ownership_document}>Ownership Document</a>
              <br />
            </>
          )}
          {rowData.logo && (
            <>
              <a href={ApiURL + rowData.logo}>Logo</a>
            </>
          )}
        </>
      )
    },
    createDateLookup(),
    updateDateLookup(),
    { title: 'Description', field: 'description' },
    {
      title: 'Status',
      field: 'status',
      lookup: {
        Approved: 'Approved',
        Declined: 'Declined',
        Pending: 'Pending'
      },
      render: (rowData) => getIdentityStatus(rowData.status)
    }
  ];
  const options = {
    columnsButton: true,
    actionsColumnIndex: columns.length,
    pageSize: 5
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        loading={loading}
        columns={columns}
        data={data}
        title="Company"
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = [...data];
              updatedData[data?.indexOf(oldData)] = newData;
              updateRow(oldData.id, newData.status, newData.description, updatedData);
              resolve();
            }, 600);
          })
        }
      />
    </div>
  );
};

export default Company;
