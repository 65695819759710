import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { API_GET_TEXT_MESSAGES_USER, token } from "constants/constants";
import { createDateLookup, updateDateLookup } from "constants/usefulFunctions";
import { withRouter } from "react-router-dom";
import AppButton from "components/AppButton";

function TextMessage(props) {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
  };

  const { user_id } = props;
  const userMobile = props.history.location?.state?.mobile;

  useEffect(() => {
    async function fetchData() {
      await axios
        .post(API_GET_TEXT_MESSAGES_USER, { user_id }, token)
        .then((res) => {
          let data = res.data.data;
          setState({
            columns: [
              { title: "Return Status", field: "return_status" },
              { title: "Return Message", field: "return_message" },
              { title: "Message ID", field: "message_id" },
              { title: "Message", field: "message" },
              { title: "Status Text", field: "status_text" },
              { title: "Sender", field: "sender" },
              { title: "Receptor", field: "receptor" },
              { title: "Send Date", field: "send_date" },
              { title: "Cost", field: "cost" },
              { title: "Delivery Status", field: "delivery_status" },
              createDateLookup(),
              updateDateLookup(),
            ],
            data: data,
          });
        });
      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div className="card">
      <AppButton
        onClick={() =>
          window.open(
            `/app/text-messages/new?mobile=${userMobile}&&user_id=${user_id}`
          )
        }
        text="Send New Message"
        size="large"
        customClassName="m-3"
      />
      <MaterialTable
        options={options}
        isLoading={loading}
        columns={state.columns}
        data={state.data}
        title="Text Message"
      />
    </div>
  );
}

export default withRouter(TextMessage);
