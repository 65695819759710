import React from "react";
import { Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Header from "components/Header";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Tour from "components/Tour";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import Users from "./routes/Main/Users";
import Tickets from "./routes/Main/Tickets";
import Profile from "./routes/ProfileUser";
import TextMessages from "./routes/TextMessages";
import Transactions from "./routes/Transactions";
import UserOperations from "./routes/UserOperations";
import GlobalMessageDialog from "components/GlobalMessageDialog";
import Income from "./routes/Income/index";
import Plan from "./routes/Plan";
import Announcement from "./routes/Announcements";
import Role from "./routes/Role";
import Admin from "./routes/Admin";

const App = () => {
  const currentUrl = "/app";

  const { drawerType, navigationStyle, horizontalNavPosition } = useSelector(
    ({ settings }) => {
      return {
        drawerType: settings.drawerType,
        navigationStyle: settings.navigationStyle,
        horizontalNavPosition: settings.horizontalNavPosition,
      };
    },
    shallowEqual
  );

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "fixed-drawer"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "collapsible-drawer"
    : "mini-drawer";

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add("ios-mobile-view-height");
  } else if (document.body.classList.contains("ios-mobile-view-height")) {
    document.body.classList.remove("ios-mobile-view-height");
  }

  return (
    <div className={`app-container ${drawerStyle}`}>
      <Tour />

      <Sidebar />
      <div className="app-main-container">
        <div
          className={`app-header ${
            navigationStyle === HORIZONTAL_NAVIGATION
              ? "app-header-horizontal"
              : ""
          }`}
        >
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === ABOVE_THE_HEADER && (
              <TopNav styleName="app-top-header" />
            )}
          <Header />
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
        </div>

        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            <GlobalMessageDialog />

            <Switch>
              {/* Dashboard */}
              <Route
                path={`${currentUrl}/dashboard`}
                component={asyncComponent(() =>
                  import("./routes/Main/Dashboard")
                )}
              />

              {/* Users */}
              <Route path={`${currentUrl}/users`} component={Users} />

              {/* Tickets */}
              <Route path={`${currentUrl}/tickets`} component={Tickets} />

              {/* Text Messages */}
              <Route
                path={`${currentUrl}/text-messages`}
                component={TextMessages}
              />

              {/* Transactions */}
              <Route
                path={`${currentUrl}/transactions`}
                component={Transactions}
              />

              {/* UserOperations */}
              <Route
                path={`${currentUrl}/user-operations`}
                component={UserOperations}
              />
              {/* Income */}
              <Route path={`${currentUrl}/income`} component={Income} />

              {/* Profile */}
              <Route path={`${currentUrl}/profile`} component={Profile} />

              {/*Plan */}
              <Route path={`${currentUrl}/plan`} component={Plan} />
              {/*Announcement */}
              <Route
                path={`${currentUrl}/announcement`}
                component={Announcement}
              />
              {/*Roles */}
              <Route path={`${currentUrl}/role`} component={Role} />
              {/*Admin */}
              <Route path={`${currentUrl}/admin`} component={Admin} />

              <Route
                component={asyncComponent(() => import("components/Error404"))}
              />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default App;
