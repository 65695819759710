import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
// --------------------------------------------------------------------------------
const Plan = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/add-plan`}
        component={asyncComponent(() => import("./routes/AddPlan"))}
      />
      <Route
        path={`${match.url}/plan-list`}
        component={asyncComponent(() => import("./routes/PlanList"))}
      />
    </Switch>
  </div>
);

export default Plan;
