import { create } from "apisauce";
import { store } from "redux/store";

const apiService = create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/admin/`,
});

apiService.addAsyncRequestTransform((request) => async () => {
  const authToken = store.getState().auth.authToken;
  if (!authToken) return;
  request.headers["Authorization"] = "Bearer " + authToken;
});

export default apiService;
