import React, { useState, useEffect } from "react";
import { API_GET_OPEN_TICKET, token } from "constants/constants";
import axios from "axios";
import {
  NavHeader,
  NavMenu,
  NavWithSubmenu,
} from "containers/SideNav/components";

const Main = () => {
  const [openTicket, setopenTicket] = useState(0);

  useEffect(() => {
    getOpenTicket();
  }, []);

  const getOpenTicket = () => {
    axios.post(API_GET_OPEN_TICKET, {}, token).then((response) => {
      setopenTicket(response?.data?.data?.length);
    });
  };

  return (
    <>
      <NavHeader title="sidebar.main" />

      <NavMenu
        title="sidebar.dashboard"
        icon="zmdi zmdi-view-dashboard zmdi-hc-fw"
        url="/app/dashboard"
        permission="See Dashboard"
      />

      <NavWithSubmenu
        title="sidebar.users"
        icon="zmdi zmdi-accounts zmdi-hc-fw"
        subMenuList={[
          {
            title: "sidebar.users.AllUsers",
            url: "/app/users/all-users",
            permission: "All User",
          },
          {
            title: "sidebar.add.individual",
            url: "/app/users/add-individual",
            permission: "Add Individual User",
          },
          {
            title: "sidebar.add.business",
            url: "/app/users/add-business",
            permission: "Add Business User",
          },
          {
            title: "sidebar.add.partner",
            url: "/app/users/add-partner",
            permission: "Add Business User",
          },
          {
            title: "sidebar.partners",
            url: "/app/users/partners",
            permission: "Add Business User",
          },
          {
            title: "sidebar.users.balance",
            url: "/app/users/users-balance",
            permission: "Balance User",
          },
        ]}
        permission="User"
      />

      {/* <NavWithSubmenu
        title="sidebar.tickets"
        icon="zmdi zmdi-comments zmdi-hc-fw"
        subMenuList={[
          {
            title: "sidebar.tickets.allTickets",
            url: "/app/tickets/all-tickets",
          },
          {
            title: "sidebar.tickets.openTickets",
            url: "/app/tickets/open-tickets",
            badge: openTicket,
          },
          {
            title: "sidebar.tickets.newTicket",
            url: "/app/tickets/new-ticket",
          },
        ]}
      /> */}
    </>
  );
};

export default Main;
