const { SHOW_MESSAGE_DIALOG, HIDE_MESSAGE_DIALOG } = require('constants/ActionTypes');

const MessageDialog = (state = { showDialog: false }, { type, payload }) => {
  switch (type) {
    case SHOW_MESSAGE_DIALOG:
      return {
        ...state,
        showDialog: true,
        title: payload.title,
        description: payload.description,
        status: payload.status
      };

    case HIDE_MESSAGE_DIALOG:
      return { ...state, showDialog: false };

    default:
      return state;
  }
};
export default MessageDialog;
