import React, { useEffect, useState } from "react";
import { Flags, Currencies, ApiURL, boot } from "constants/constants";
import {
  createDateLookup,
  getToggleStatus,
  renderAmount,
  thousands_separators,
  updateDateLookup,
} from "constants/usefulFunctions";
import MainTable from "components/MainTable";
import useAPI from "services/hook";
import APIs from "services/API/ProfileUser";

const UserBalance = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [lookup, setLookup] = useState(null);

  useEffect(() => {
    if (boot.currencies && boot.currencies.length > 1) {
      const obj2 = boot.currencies.reduce((acc, cur) => {
        acc[cur.id] = cur.code;
        return acc;
      }, {});

      setLookup(obj2);
    }
  }, [boot.currencies]);

  useEffect(() => {
    if (lookup) fetchData();
  }, [lookup]);

  const apiGetBalance = useAPI(APIs.apiGetBalance);
  const fetchData = async () => {
    const result = await apiGetBalance.request({ user_id });
    if (result) {
      var formatedData = result?.map((item) => {
        item.websiteLogo = ApiURL + item.website_logo;
        return item;
      });
      setData(formatedData);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Currency",
      field: "currency_id",
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <i
                className={`flag flag-24 flag-${Flags[rowData.currency_id]}`}
              />
            </div>
            <div
              className="jr-profile-banner-avatar-info"
              style={{ marginTop: -4, marginLeft: -10 }}
            >
              {Currencies[rowData.currency_id]}
            </div>
          </div>
        </>
      ),
      lookup: lookup,
    },
    {
      title: "Status",
      field: "status",
      lookup: { Active: "Active", Inactive: "Inactive" },
      initialEditValue: "Active",
      render: (rowData) => getToggleStatus(rowData.status),
    },
    {
      title: "Balance",
      field: "balance",
      editable: "never",
      render: (rowData) => renderAmount(rowData, "balance"),
    },
    {
      title: "Hold",
      field: "pending_balance",
      editable: "never",
      render: (rowData) => renderAmount(rowData, "pending_balance"),
    },
    createDateLookup(),
    updateDateLookup(),
    {
      title: "Available",
      field: "balance",
      editable: "never",
      render: (rowData) =>
        thousands_separators(
          Math.round(((rowData.balance * 100) / 105) * 100) / 100
        ) +
        " " +
        rowData.symbol,
    },
  ];

  const options = {
    actionsColumnIndex: columns.length,
  };

  const apiCreateBalance = useAPI(APIs.apiCreateBalance, true);
  const addRow = async (newData, updatedData) => {
    const result = await apiCreateBalance.request({
      user_id,
      currency_id: newData.currency_id,
    });
    if (result) setData([...updatedData]);
  };

  const apiDeleteBalance = useAPI(APIs.apiDeleteBalance, true);
  const deleteRow = async (currency_id, updatedData) => {
    const result = await apiDeleteBalance.request({ user_id, currency_id });
    if (result) setData([...updatedData]);
  };

  return (
    <div className="card">
      <MainTable
        loading={loading}
        customOptions={options}
        columns={columns}
        data={data}
        title="Balance"
        onRowAdd={(newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = [...data];
              updatedData.push(newData);
              addRow(newData, updatedData);
              resolve();
            }, 600);
          })
        }
        onRowDelete={(oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = data?.filter(
                (item) => item.id !== oldData.id
              );
              deleteRow(oldData.currency_id, updatedData);
              resolve();
            }, 600);
          })
        }
      />
    </div>
  );
};

export default UserBalance;
