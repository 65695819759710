import React from "react";
import UserInfo from "./UserInfo";
import StatusLoading from "../StatusLoading";
import CardHeader from "components/CardHeader";

const Summary = ({ user }) => {
  return (
    <div className="jr-card jr-full-card">
      <CardHeader title="summary" status={user?.status} />
      <div style={{ padding: "24px" }}>
        {user.id ? <UserInfo basicInfo={user} /> : <StatusLoading />}
      </div>
    </div>
  );
};

export default Summary;
