import React from "react";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

const useStyles = makeStyles({
  root: (props) => ({
    width: props?.fullWidth ? "100%" : 300,
    margin: 10,
    backgroundColor: props?.backgroundColor,
    ...props.customClass,
  }),
});

const AppTextInput = ({
  label,
  value,
  onChange,
  variant = "outlined",
  fullWidth = false,
  readOnly = false,
  shrink = true,
  helperText,
  error = false,
  multiline = false,
  rows = 4,
  margin = "normal",
  color = "primary",
  dir = "ltr",
  disabled,
  type = "text",
  pattern,
  maxLength,
  required = false,
  onFocus,
  onBlur,
  backgroundColor,
  hidden,
  customClass,
  ...otherProps
}) => {
  const classes = useStyles({ customClass, fullWidth, backgroundColor });

  return (
    <TextField
      label={label}
      className={classNames(classes.root, customClass)}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      type={type}
      disabled={disabled}
      variant={variant}
      helperText={helperText}
      error={error}
      dir={dir}
      multiline={multiline}
      margin={margin}
      fullWidth={fullWidth}
      color={color}
      rows={rows}
      required={required}
      hidden={hidden}
      InputLabelProps={{
        shrink,
        readOnly,
      }}
      inputProps={{ pattern, maxLength }}
      {...otherProps}
    />
  );
};

export default AppTextInput;
