import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const StatusLoading = () => {
  return (
    <div className="row">
      <div style={{ minHeight: '250px' }} className=" mx-auto">
        <CircularProgress />
      </div>
    </div>
  );
};

export default StatusLoading;
