import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./reducers";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
// --------------------------------------------------------------------------------
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  timeout: 0,
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  whitelist: ["auth"],
};
const persistedReducer = persistReducer(persistConfig, reducers(history));

const store = createStore(
  persistedReducer,
  {
    /* set your initial state here */
  },
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history)
      // add your own middlewares here
    )
    // add your own enhancers here
  )
);
let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor, history };
