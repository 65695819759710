import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AppButton from 'components/AppButton';
import AppTextInput from 'components/AppTextInput';

const DescriptionDialog = ({
  name,
  onSubmit,
  isOpen,
  onDescriptionChange,
  description,
  dialogTitle,
  dialogContent,
  error,
  attachment,
  onChangeAttachment,
  loading,
  onClose
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>

        <AppTextInput
          name={name}
          value={description}
          onChange={onDescriptionChange}
          multiline
          error={error}
          margin="dense"
          autoFocus
        />
        {error && <p className="text-danger my-2">This field is required</p>}

        {onChangeAttachment && (
          <Button className="bg-light jr-btn jr-btn-sm text-dark mt-2" component="label">
            <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
            Choose File
            <input type="file" onChange={onChangeAttachment} style={{ display: 'none' }} />
          </Button>
        )}
        {attachment && (
          <div className="mt-3">
            File Name: <span className="text-success mr-2">{attachment.name}</span>
            File Size: <span className="text-success">{Math.trunc(attachment.size / 1024)}KB</span>
          </div>
        )}
      </DialogContent>
      <DialogActions className="px-4">
        <AppButton text={'Cancel'} onClick={onClose} loading={loading} status="danger" />
        <AppButton text={'Submit'} onClick={onSubmit} loading={loading} status="success" />
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionDialog;
