import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Announcement = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/add`}
        component={asyncComponent(() => import("./routes/AddAnnouncement"))}
      />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/AnnouncementList"))}
      />
    </Switch>
  </div>
);

export default Announcement;
