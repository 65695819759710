import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { API_USER_LOG, token, boot } from "constants/constants";
import { createDateLookup, updateDateLookup } from "constants/usefulFunctions";

function UserLog({ user_id }) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});

  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
  };

  useEffect(() => {
    async function fetchData() {
      await axios.post(API_USER_LOG, { user_id }, token).then((res) => {
        let data = res.data.data;
        console.log(data, "dd");
        setState({
          columns: [
            { title: "Key", field: "key", editable: "never" },
            { title: "Value", field: "value", editable: "never" },
            {
              title: "Type",
              field: "type",
              editable: "never",
              render: (rowData) =>
                boot.admins && rowData.admin_id
                  ? boot.admins.find((o) => o.id === rowData.admin_id)
                      .first_name +
                    " " +
                    boot.admins.find((o) => o.id === rowData.admin_id).last_name
                  : "user",
            },
            createDateLookup(),
            updateDateLookup(),
          ],
          data: data,
        });
      });
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className="card">
      <MaterialTable
        isLoading={loading}
        options={options}
        columns={state.columns}
        data={state.data}
        title="UserLog"
      />
    </div>
  );
}

export default UserLog;
