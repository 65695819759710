import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import axios from "axios";
import { API_GET_SIGN_IN } from "constants/constants";
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  hideAuthLoader,
} from "../redux/actions/Auth";
import AppButton from "components/AppButton";
import AppTextInput from "components/AppTextInput/index";
import Logo from "assets/images/logo.svg";

const SignIn = () => {
  const [showError, setShowError] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [verify_token, setVerify_token] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const dispatch = useDispatch();

  const { loader, alertMessage, showMessage, showSuccessMessage } = useSelector(
    ({ auth }) => {
      return {
        loader: auth.loader,
        alertMessage: auth.alertMessage,
        showMessage: auth.showMessage,
        showSuccessMessage: auth.showSuccessMessage,
      };
    },
    shallowEqual
  );

  const notificationError = useCallback(() => {
    return NotificationManager.error(
      "Authentication Failed, Please try again!"
    );
  }, []);

  const tokenError = useCallback(() => {
    return NotificationManager.error(alertMessage);
  }, [alertMessage]);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }

    if (showError) {
      setShowError(false);
    }

    if (showSuccessMessage) {
      dispatch(hideMessage());
    }
  }, [showSuccessMessage, showError, showMessage]);

  const handleFormSubmit = () => {
    dispatch(showAuthLoader());

    if (verify_token) {
      dispatch(
        userSignIn({ email, password, provider: "admins", verify_token })
      );
    } else {
      axios
        .post(API_GET_SIGN_IN, { email, password, provider: "admins" })
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          if (response.status === "success") {
            setShowVerify(true);
            dispatch(hideAuthLoader());
          }
        })
        .catch(() => {
          setShowError(true);
          dispatch(hideAuthLoader());
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleFormSubmit();
    }
  };
  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-login-content">
          <div className="app-login-header mb-4 text-center">
            <img
              src={Logo}
              alt="Yekpay"
              title="Yekpay"
              className="app-login-header mb-4"
            />
            <h3>Login To Administration Area</h3>
          </div>

          <form>
            <div className="app-login-form">
              <AppTextInput
                label={<IntlMessages id="appModule.email" />}
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                customClass="my-3"
                onKeyDown={handleKeyDown}
              />
              <AppTextInput
                type="password"
                label={<IntlMessages id="appModule.password" />}
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                customClass="my-3"
                onKeyDown={handleKeyDown}
              />

              {showVerify && (
                <AppTextInput
                  label="Token"
                  fullWidth
                  onChange={(event) => setVerify_token(event.target.value)}
                  value={verify_token}
                  autoFocus
                  customClass="my-3"
                  onKeyDown={handleKeyDown}
                />
              )}

              <div className="mb-3 mt-3 d-flex align-items-center justify-content-between">
                <AppButton
                  text={<IntlMessages id="appModule.signIn" />}
                  onClick={!loader && handleFormSubmit}
                  variant="contained"
                  color="primary"
                  loading={loader}
                  disabled={!email || !password}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {showMessage && tokenError()}
      {showError && notificationError()}
      {showSuccessMessage && NotificationManager.success(alertMessage)}
      <NotificationContainer />
    </div>
  );
};

export default SignIn;
