import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const UserOperations = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/topup`}
        component={asyncComponent(() => import("./routes/Topup"))}
      />
      <Route
        path={`${match.url}/topup-list`}
        component={asyncComponent(() => import("./routes/TopupList"))}
      />
      <Route
        path={`${match.url}/payout`}
        component={asyncComponent(() => import("./routes/Payout"))}
      />
      <Route
        path={`${match.url}/exchange`}
        component={asyncComponent(() => import("./routes/Exchange"))}
      />
      <Route
        path={`${match.url}/internal-transfer`}
        component={asyncComponent(() => import("./routes/InternalTransfer"))}
      />
    </Switch>
  </div>
);

export default UserOperations;
