import React, { useEffect, useState } from "react";
import { Currencies, Plan, boot } from "constants/constants";
import {
  createDateLookup,
  thousands_separators,
} from "constants/usefulFunctions";
import useAPI from "services/hook";
import APIs from "services/API/ProfileUser";
import MainTable from "components/MainTable";

const UserPlans = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const options = {
    actionsColumnIndex: data.columns && data.columns.length,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const apiGetPlans = useAPI(APIs.apiGetPlans);
  const fetchData = async () => {
    const result = await apiGetPlans.request({ user_id });
    if (result) {
      setData(result);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Plan",
      field: "plan_id",
      render: (rowData) => Plan[rowData.plan_id],
      lookup: {
        "1": "Personal",
        "2": "Business",
        "3": "Business Plus",
      },
      initialEditValue: "2",
    },
    {
      title: "Type",
      field: "type",
      lookup: {
        Renew: "Renew",
        Start: "Start",
        Gift: "Gift",
        "Back To Personal": "Back To Personal",
        Trial: "Trial",
      },
      initialEditValue: "Gift",
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => rowData && thousands_separators(rowData.amount),
      initialEditValue: "0",
    },
    {
      title: "Currency",
      field: "currency_id",
      render: (rowData) => Currencies[rowData.currency_id],
      lookup: Currencies,
    },
    {
      title: "Duration",
      field: "duration",
      initialEditValue: "1",
      lookup: {
        "1": "1 Month",
        "2": "2 Months",
        "3": "3 Months",
        "4": "4 Months",
        "5": "5 Months",
        "6": "6 Months",
        "7": "7 Months",
        "8": "8 Months",
        "9": "9 Months",
        "10": "10 Months",
        "11": "11 Months",
        "12": "12 Months",
      },
    },
    { title: "Description", field: "description" },
    {
      title: "Admin",
      field: "admin_id",
      editable: "false",
      initialEditValue: Number(localStorage.getItem("admin_id")),
      render: (rowData) =>
        boot.admins && rowData.admin_id
          ? boot.admins.find((o) => o.id === rowData.admin_id).first_name +
            " " +
            boot.admins.find((o) => o.id === rowData.admin_id).last_name
          : "-",
    },
    {
      title: "Status",
      field: "status",
      editable: "false",
      initialEditValue: "Active",
    },
    { title: "Start", field: "start_date", editable: "false" },
    { title: "End", field: "end_date", editable: "false" },
    createDateLookup(),
    {
      title: "Transaction",
      field: "transaction_id",
      editable: "false",
    },
  ];

  const apiCreatePlans = useAPI(APIs.apiCreatePlans, true);
  const addRow = async (
    plan_id,
    type,
    amount,
    currency_id,
    duration,
    description,
    updatedData
  ) => {
    const result = await apiCreatePlans.request({
      plan_id,
      type,
      amount,
      currency_id,
      duration,
      description,
      updatedData,
    });
    if (result) setData([...updatedData]);
  };

  return (
    <div className="card">
      <MainTable
        loading={loading}
        customOptions={options}
        columns={columns}
        data={data}
        title="Plans"
        onRowAdd={(newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = [...data];
              updatedData.push(newData);
              addRow(
                newData.plan_id,
                newData.type,
                newData.amount,
                newData.currency_id,
                newData.duration,
                newData.description,
                updatedData
              );
              resolve();
            }, 600);
          })
        }
      />
    </div>
  );
};

export default UserPlans;
