import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  createDateLookup,
  getIdentityStatus,
  updateDateLookup,
} from "constants/usefulFunctions";
import { boot } from "constants/constants";
import moment from "moment";
import sha1 from "sha1";
import MainTable from "components/MainTable";
import useAPI from "services/hook";
import APIs from "services/API/ProfileUser";
import AppButton from "components/AppButton";
import AppTextInput from "components/AppTextInput";

const Billing = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState([]);
  const [values, setValues] = useState({
    success: false,
    error: false,
    postal_code: "",
    address: "",
    addressErr: "",
  });

  const handleSave = () => {
    setLoadingSubmit(true);
    let date = new Date();
    let formattedDate = moment(date).format("YYYYMMDD").toString();
    let postalCode = values.postal_code;
    let password = "oFA6YehzAT4bnWw5u6L6";
    let shaString = password + "#" + postalCode + "#" + formattedDate;
    let hash = sha1(shaString);
    hash = hash.toUpperCase();
    const url =
      "http://wss.postcode.post.ir:8080/api2/v1/PostCode.svc/json/addresses/string/" +
      postalCode +
      "?u=Yekpay&h=" +
      hash;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setValues({
            ...values,
            success: true,
            address: response.data.GetAddressStringByPostCodeResult.Value,
            addressErr:
              response.data.GetAddressStringByPostCodeResult.ErrorMessage,
          });
        } else {
          setValues({
            ...values,
            success: false,
            addressErr: "خطا در اتصال به وب سرویس",
          });
        }
        setLoadingSubmit(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (let prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setLoadingSubmit(false);
      });
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const apiGetBilling = useAPI(APIs.apiGetBilling);
  const fetchData = async () => {
    const result = await apiGetBilling.request({ user_id });
    if (result) {
      setData(result);
      setValues({
        ...values,
        postal_code: result.length > 0 ? result[0].postal_code : null,
      });
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Admin",
      field: "admin_id",
      editable: "never",
      render: (rowData) =>
        boot.admins && rowData.admin_id
          ? boot.admins.find((o) => o.id === rowData.admin_id).first_name +
            " " +
            boot.admins.find((o) => o.id === rowData.admin_id).last_name
          : "-",
    },
    {
      title: "Country",
      field: "country_id",
      editable: "never",
      render: (rowData) =>
        boot.countries && rowData.country_id
          ? boot.countries.find((o) => o.id === rowData.country_id).name_en
          : "-",
    },
    { title: "City", field: "city", editable: "never" },
    { title: "State", field: "state", editable: "never" },
    { title: "Address", field: "address", editable: "never" },
    { title: "Postal Code", field: "postal_code", editable: "never" },
    createDateLookup(),
    updateDateLookup(),
    {
      title: "Operator",
      field: "operator",
      editable: "never",
      hidden: true,
    },
    { title: "Description", field: "admin_description" },

    {
      title: "Status",
      field: "status",
      lookup: {
        Approved: "Approved",
        Declined: "Declined",
        Pending: "Pending",
      },
      render: (rowData) => getIdentityStatus(rowData.status),
    },
  ];
  const options = {
    actionsColumnIndex: columns.length,
  };

  const apiChangeStatusBilling = useAPI(APIs.apiChangeStatusBilling);
  const updateRow = async (user_id, id, status, description, updatedData) => {
    const result = await apiChangeStatusBilling.request({
      user_id,
      id,
      status,
      description: description,
    });
    if (result) setData([...updatedData]);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <div className="jr-card">
            <div className="row">
              <div className="col-12 d-flex align-items-center flex-wrap">
                <AppTextInput
                  name="postal_code"
                  label="PostalCode"
                  value={values.postal_code}
                  onChange={(e) => handleChange(e)}
                />

                <AppTextInput
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={(e) => handleChange(e)}
                />
                <span className="text-danger">{values.addressErr}</span>

                <AppButton
                  style={{
                    width: "100px",
                  }}
                  loading={loadingSubmit}
                  onClick={handleSave}
                  text="Submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <MainTable
          customOptions={options}
          loading={loading}
          columns={columns}
          data={data}
          title="Billing"
          onRowUpdate={(newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                const updatedData = [...data];
                updatedData[data?.indexOf(oldData)] = newData;
                updateRow(
                  oldData.user_id,
                  oldData.id,
                  newData.status,
                  newData.admin_description,
                  updatedData
                );
                resolve();
              }, 600);
            })
          }
        />
      </div>
    </>
  );
};

export default Billing;
