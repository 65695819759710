import React, { useEffect, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import AppLocale from "../lngProvider";
import "styles/AppStyles.scss";
import { useIdleTimer } from "react-idle-timer";
import MainApp from "../app";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import SignIn from "./SignIn";
import { logOut } from "constants/usefulFunctions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const RestrictedRoute = ({ component: Component, authToken, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authToken ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = (props) => {
  const { match, location, locale, authToken, isDirectionRTL } = props;
  const memoizedUser = useMemo(() => authToken, [authToken]);

  const onIdle = () => {
    logOut();
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
    crossTab: true,
  });

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  }, []);

  if (location.pathname === "/") {
    return <Redirect to={"/signin"} />;
  }
  const applyTheme = createTheme(defaultTheme);

  if (isDirectionRTL) {
    applyTheme.direction = "rtl";
    document.body.classList.add("rtl");
  } else {
    document.body.classList.remove("rtl");
    applyTheme.direction = "ltr";
  }

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ThemeProvider theme={applyTheme}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute
                  path={`${match.url}app`}
                  authToken={memoizedUser}
                  component={MainApp}
                />
                <Route path="/signin" component={SignIn} />

                <Route
                  component={asyncComponent(() =>
                    import("components/Error404")
                  )}
                />
              </Switch>
            </div>
          </RTL>
        </LocalizationProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = ({ settings, auth }) => {
  return {
    locale: settings.locale,
    isDirectionRTL: settings.isDirectionRTL,
    authToken: auth.authToken,
  };
};

export default connect(mapStateToProps)(App);
