import React from "react";
import { hideMessageDialog } from "redux/actions/DialogAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MessageDialog from "components/MessageDialog/index";

const GlobalMessageDialog = () => {
  const {
    showDialog,
    description,
    title,
    status = "success",
  } = useSelector(({ MessageDialog }) => {
    return {
      showDialog: MessageDialog.showDialog,
      description: MessageDialog?.description,
      title: MessageDialog?.title,
      status: MessageDialog?.status,
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  if (!showDialog) return null;
  return (
    <MessageDialog
      status={status}
      onConfirm={() => dispatch(hideMessageDialog())}
      title={title}
      description={description}
    />
  );
};

export default GlobalMessageDialog;
