import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Users = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}/all-users`}
          component={asyncComponent(() => import("./routes/AllUsers"))}
          exact
        />
        <Route
          path={`${match.url}/add-individual`}
          component={asyncComponent(() => import("./routes/AddIndividual"))}
        />
        <Route
          path={`${match.url}/add-business`}
          component={asyncComponent(() => import("./routes/AddBusiness"))}
        />
        <Route
          path={`${match.url}/add-partner`}
          component={asyncComponent(() => import("./routes/AddPartner"))}
        />
        <Route
          path={`${match.url}/partners`}
          component={asyncComponent(() => import("./routes/Partners"))}
        />
        <Route
          path={`${match.url}/users-balance`}
          component={asyncComponent(() => import("./routes/UserBalance"))}
        />
        <Route
          path={`${match.url}/:id`}
          component={asyncComponent(() => import("./routes/UserDetail"))}
        />
      </Switch>
    </div>
  );
};

export default Users;
