import React, { useState, useEffect } from "react";
import RequestInfo from "./RequestInfo";
import axios from "axios";
import StatusLoading from "../StatusLoading";
import { API_GET_USER, token } from "constants/constants";

const RequestPayout = ({ user_id }) => {
  const [user, setUser] = useState({});
  useEffect(() => {
    async function fetchData() {
      await axios
        .post(API_GET_USER, { user_id }, token)
        .then((res) => res.data.data)
        .then((res) => setUser(res.basic));
    }
    fetchData();
  }, []);

  return (
    <div className="jr-card jr-full-card">
      <div className="card-header">
        <h4 className="card-title mb-0">Request Payout</h4>
      </div>
      <div style={{ padding: 8 * 3 }}>
        {user.id ? (
          <RequestInfo basicInfo={user} user_id={user_id} />
        ) : (
          <StatusLoading />
        )}
      </div>
    </div>
  );
};

export default RequestPayout;
