import React from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";

const Admin = () => {
  return (
    <>
      <NavHeader title="sidebar.admin" permission="Admin" />
      <NavMenu
        title="sidebar.add.admin"
        icon="zmdi zmdi-account-add zmdi-hc-fw"
        url="/app/admin/add"
        permission="Create Admin"
      />
      <NavMenu
        title="sidebar.admin.list"
        icon="zmdi zmdi-accounts zmdi-hc-fw"
        url="/app/admin/list"
        permission="All Admin"
      />
    </>
  );
};

export default Admin;
